<script setup>
// layout -> bắt buộc
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { FormInstance } from "element-plus";
import MethodService from "../../service/MethodService";
import DataForm from "./dataFormUser";
import mushroom from "cem-primary-api";
import Swal from "sweetalert2";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
import { Filter } from "mushroomjs";
import { watch } from "vue";
const { t } = useI18n();
const ruleFormRef = ref(FormInstance);
import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const roles = ref([]);
const idAdmin = ref(""); // id của tài khoản admin để kiểm tra khi xóa
let loading = ref(true); // loading
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const dataTables = reactive({ value: [] }); // data bảng
const optionsRolesAdmin = MethodService.copyObject(DataForm.optionsRolesAdmin); // roles tài khoản
const optionsRolesAdminPrimary = MethodService.copyObject(
  DataForm.optionsRolesAdminPrimary
); // roles tài khoản
const optionsUser = ref(MethodService.copyObject(DataForm.optionsUser)); // roles tài khoản
const pageSizeDefault = ref(20); // số bản ghi mặc định ở phân trang
const fn_handle = (type, scope, row) => {
  if (type == "update") {
    router.push({
      name: "CapNhatTaiKhoan",
      params: { id: row.id },
    });
  }
  if (type == "view") {
    router.push({
      name: "ChiTietTaiKhoan",
      params: { id: row.id },
    });
  }
  if (type == "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_account_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.user.deleteAsync({
            id: row.id,
          });

          if (response.result) {
            if (idAdmin.value === row.id) {
              toastr.error(t("t_account_not_login"));
              window.localStorage.clear();
              setTimeout(() => {
                let urlLogin = location.origin + "/login";
                window.location.replace(urlLogin);
              }, 2000);
            } else {
              fn_LayDanhSachNguoiDung();
              toastr.success(t("toastr_delete_success"));
            }
          }
        } catch (e) {
          if (e.code === 40001) {
            toastr.error(t("t_cannot_delete_last_admin"));
          } else MethodService.showError(e.code);
        }
      }
    });
  }
};
// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      loading.value = true;
      if (tableRules.dataSearch.value["account"])
        tableRules.dataSearch.value["account"] =
          tableRules.dataSearch.value["account"].trim();

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      if (
        tableRules.dataSearch.value["roles"] &&
        tableRules.dataSearch.value["roles"] === "User"
      ) {
        tableRules.filters += "&roles:nin:Edit,Admin,AdminLocal";
      }
      tableRules.offset = 0;
      tableRules.sort = "";
      fn_LayDanhSachNguoiDung();
    } else return false;
  });
};
// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  fn_LayDanhSachNguoiDung();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  fn_LayDanhSachNguoiDung();
};

const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query; // không phải router
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};

// chuyển sang màn thêm mới tài khoản
const fn_ThemMoiTaiKhoan = () => {
  router.push({ name: "ThemMoiTaiKhoan" });
};

// lấy toàn bộ danh sách người dùng
const fn_LayDanhSachNguoiDung = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .replace({
      name: "DanhSachNguoiDung",
      query: {
        limit: tableRules.limit,
        page: tableRules.page,
        sort: tableRules.sort,
        filters: tableRules.filters,
        showFormSearch: tableRules.showFormSearch,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.user.listAsync(dataFilter);
    if (response.result) {
      let dataRoleUser = await getInfoRoleUser(response.result);
      let dataFullNameUser = await getFullNameUsers(dataRoleUser);
      let dataDepartmentUsers = await getDepartmentUsers(dataFullNameUser);
      let _data = await getSubDepartmentUsers(dataDepartmentUsers);
      dataTables.value = await getPositionUsers(_data);
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};
const getInfoRoleUser = (data) => {
  data.forEach((item) => {
    if (item.roles.includes("Admin")) {
      item.roles = "Super Admin";
    } else if (item.roles.includes("AdminPrimary")) {
      item.roles = "Admin";
    } else if (item.roles.includes("AdminLocal")) {
      item.roles = "Admin Local";
    } else if (item.roles.includes("Edit")) {
      item.roles = "Edit";
    } else item.roles = "View";
  });
  return data;
};
const getFullNameUsers = async (data) => {
  if (!data || data?.length === 0) return;
  let _data = undefined;
  const idsUser = [...new Set(data.map((item) => item.id))];
  const profileUserArr = await mushroom.profile.getAllAsync({
    filters: Filter.in("id", idsUser).build(),
  });
  if (profileUserArr.result) {
    data.forEach((item) => {
      profileUserArr.result.forEach((itemDataProfileUser) => {
        if (item.id === itemDataProfileUser.id) {
          item.fullname = itemDataProfileUser.fullname;
          item.department_id = itemDataProfileUser.department_id;
          item.sub_department_id = itemDataProfileUser.sub_department_id;
          item.position_id = itemDataProfileUser.position_id;
        }
      });
    });
    _data = data;
  }

  return _data;
};
const getPositionUsers = async (data) => {
  if (!data || data?.length === 0) return;
  let _data = undefined;
  const idsPosition = [
    ...new Set(
      data.filter((item) => item.position_id).map((item) => item.position_id)
    ),
  ];
  const positionUserArr = await mushroom.position.getAllAsync({
    filters: Filter.in("id", idsPosition).build(),
  });

  if (positionUserArr.result) {
    data.forEach((item) => {
      positionUserArr.result.forEach((itemDataPositionUser) => {
        if (item.position_id === itemDataPositionUser.id)
          item.position_name = itemDataPositionUser.name;
      });
    });
    _data = data;
  }

  return _data;
};
const getDepartmentUsers = async (data) => {
  if (!data || data?.length === 0) return;
  let _data = undefined;
  const idsDepartment = [
    ...new Set(
      data
        .filter((item) => item.department_id)
        .map((item) => item.department_id)
    ),
  ];
  let departmentUserArr = await mushroom.department.getAllAsync({
    filters: Filter.in("id", idsDepartment).build(),
  });

  if (departmentUserArr.result?.length > 0) {
    data.forEach((item) => {
      departmentUserArr.result.forEach((itemDataDepartmentUser) => {
        if (item.department_id === itemDataDepartmentUser.id)
          item.department_name = itemDataDepartmentUser.name;
      });
    });
    _data = data;
  }

  return _data;
};

const getSubDepartmentUsers = async (data) => {
  if (!data || data?.length === 0) return;
  const idsSubDepartment = [
    ...new Set(
      data
        .filter((item) => item.sub_department_id)
        .map((item) => item.sub_department_id)
    ),
  ];
  let subDepartmentUserArr = await mushroom.sub_department.getAllAsync({
    filters: Filter.in("id", idsSubDepartment).build(),
  });
  if (subDepartmentUserArr.result?.length > 0) {
    data.forEach((item) => {
      subDepartmentUserArr.result.forEach((itemDataSubDepartmentUser) => {
        if (item.sub_department_id === itemDataSubDepartmentUser.id)
          item.sub_department_name = itemDataSubDepartmentUser.name;
      });
    });
  }
  return data;
};

watch(
  () => lang_web.showLang,
  () => {
    optionsUser.value = MethodService.copyObject(DataForm.optionsUser);
  }
);

onMounted(async () => {
  fn_hashUrl();
  await fn_LayDanhSachNguoiDung();
  const response = JSON.parse(localStorage.getItem("userInfo"));
  roles.value = response.result.roles;
  idAdmin.value = response.result.id;
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-list-users") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_ThemMoiTaiKhoan"
              v-if="roles.includes('Admin') || roles.includes('AdminPrimary')"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="validForm.value"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="4">
                      <el-form-item :label="$t('t-account')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['account']"
                          :placeholder="$t('t-place-account')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item :label="$t('t-roles-account')">
                        <el-select
                          :placeholder="$t('t-place-roles-account')"
                          clearable
                          v-model="tableRules.dataSearch.value['roles']"
                          style="width: 100%"
                          v-if="roles.includes('Admin')"
                        >
                          <el-option
                            v-for="item in optionsRolesAdmin"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <el-select
                          :placeholder="$t('t-place-roles-account')"
                          clearable
                          v-model="tableRules.dataSearch.value['roles']"
                          style="width: 100%"
                          v-else
                        >
                          <el-option
                            v-for="item in optionsRolesAdminPrimary"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item :label="$t('t-state-account')">
                        <el-select
                          :placeholder="$t('t-place-state-account')"
                          clearable
                          v-model="tableRules.dataSearch.value['disabled']"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in optionsUser"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      type="submit"
                      @click="fn_TimKiem(ruleFormRef)"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            min-height="550"
            v-loading="loading"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="50"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="account"
              :label="$t('t-account')"
              min-width="150"
              align="start"
              :sortable="true"
            />
            <el-table-column
              prop="fullname"
              :label="$t('t_full_name')"
              min-width="180"
              align="start"
            />
            <el-table-column
              prop="position_name"
              :label="$t('t_position_name')"
              min-width="150"
              align="start"
            >
              <template #default="scope">
                <div class="overflowBox">
                  {{ scope.row.position_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="sub_department_name"
              :label="$t('t_sub_department')"
              min-width="150"
              align="start"
            >
              <template #default="scope">
                <div class="overflowBox">
                  {{ scope.row.sub_department_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="department_name"
              :label="$t('t-departments')"
              min-width="150"
              align="start"
            />
            <el-table-column
              prop="roles"
              :label="$t('t-roles-account')"
              width="120"
              align="center"
            >
              <template #default="scope">
                <span
                  class="badge text-uppercase"
                  :class="{
                    'badge-soft-secondary': scope.row.roles === 'Super Admin',
                    'badge-soft-danger': scope.row.roles === 'Admin',
                    'badge-soft-success': scope.row.roles === 'Admin Local',
                    'badge-soft-info': scope.row.roles === 'Edit',
                    'badge-soft-warning': scope.row.roles === 'View',
                  }"
                  >{{ scope.row.roles }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="disabled"
              :label="$t('t-state-account')"
              align="center"
              width="120"
            >
              <template #default="scope">
                <span
                  v-if="scope.row.disabled === false"
                  class="badge badge-soft-success text-uppercase"
                  >{{ $t("t-active-user") }}</span
                >
                <span v-else class="badge badge-soft-danger text-uppercase">{{
                  $t("t-block-user")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_view')"
                    placement="top"
                  >
                    <button
                      v-if="
                        roles.includes('Admin') ||
                        roles.includes('AdminPrimary') ||
                        roles.includes('AdminLocal')
                      "
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      @click="fn_handle('view', scope.$index, scope.row)"
                    >
                      <i class="ri-eye-line"></i>
                    </button>
                  </el-tooltip>

                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_option')"
                    placement="top"
                  >
                    <button
                      v-if="
                        roles.includes('Admin') ||
                        roles.includes('AdminPrimary')
                      "
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>

                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_delete')"
                    placement="top"
                  >
                    <button
                      v-if="
                        roles.includes('Admin') ||
                        roles.includes('AdminPrimary')
                      "
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./nguoidung.scss" />
